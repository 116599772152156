import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import BrowserCards from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/BrowserCards';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Clear your Browser History",
  "path": "/Quick_Installation/How_To_Clear_Your_Browsing_History/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Clear your Browser History",
  "image": "./QI_SearchThumb_ClearBrowsingHistory.png",
  "social": "/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Clear_History_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Clear your Browser History' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Clear your Browser History' image='/images/Search/QI_SearchThumb_ClearBrowsingHistory.png' twitter='/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Leeren_des_Browserverlaufs/' locationFR='/fr/Quick_Installation/How_To_Clear_Your_Browsing_History/' crumbLabel="Browser History" mdxType="BreadCrumbs" />
    
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "flush-old-parts-of-the-web-user-interface-after-a-webui-update",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#flush-old-parts-of-the-web-user-interface-after-a-webui-update",
        "aria-label": "flush old parts of the web user interface after a webui update permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flush old parts of the web user interface after a WebUI update`}</h2>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. Here is how you can get rid of your browsers browsing history in several common browsers:`}</p>
    <BrowserCards mdxType="BrowserCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      